import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    chatLeft: {
      position: 'absolute',
      left: 50,
      top: 10,
      width: '30%',
      height: '80%',
    },
    chatContainer: {
      borderRadius: 5,
      overflow: 'hidden',
      position: 'fixed',
      top: '3%',
      right: 15,
      height: '85%',
      width: '90%',
      border: '3px solid rgba(0, 0, 0, 0.05)',
      zIndex: 9999,
      [theme.breakpoints.up('sm')]: {
        width: '50%', // tablets
      },
      [theme.breakpoints.up('md')]: {
        width: '33.33%', // medium screens
      },
      [theme.breakpoints.up('lg')]: {
        width: '25%', // large screens
      },
    },
    chatContainerVisible: {
      display: 'block',
    },
    chatContainerHidden: {
      display: 'none',
    },
  })
);

export default useStyles;
