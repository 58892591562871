import './AppointmentTools.css';

import { Accessible, Add, Delete } from '@material-ui/icons';
import React, { Component } from 'react';

import AppointmentApiInvoker from '../../api/AppointmentApiInvoker';
import AppointmentsToolsService from '../../containers/AppointmentsToolsService';
import Button from '../CustomButtons/Button';
import ButtonSpinner from '../ButtonSpinner';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import CustomDialog from '../CustomDialog/index';
import CustomSweetAlert from '../CustomSweetAlert';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import PropTypes from 'prop-types';
import Snackbar from '../Snackbar/Snackbar';
import Table from '../Table/Table';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';

class AppointmentTools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tools: this.props.appointmentsTools,
            loading: undefined,
            open: false,
            deleteAlert: false,
            error: false,
            remove: '',
            current: null,
            action: '',
            buttonEnabled: true,
            alertOpen: false,
            alertErrorOpen: false,
            alertStatus: '',
            removeItems: [],
            color: 'info',
            message:'',
            openDialog: false,
            showConfirmUndo: false,
            showConfirmSave: false,
            showConfirmExit: false,
            has_changes: false,
            tools_holds: [],
            busy: false,
        }
    }

    componentWillUnmount(){
        if (!this.props.assignAgenda) {
            this.props.onClearSelectedTools();
        }
    }

    componentWillReceiveProps(next) {
        if (next.performSave && next.performSave !== this.props.performSave) {
            this.confirmSave();
        } 
        if (next.appointmentIdToSave) {
            this.setState({ appointmentIdToSave: next.appointmentIdToSave });
        }
    }
    
    componentDidMount(){
        if (this.props.componentAction === 'view' ){
            this.getSelectedTools(this.props.onGetAppointmentsTools);
        }
        if (this.props.componentAction === 'edit') {
            this.setState({ original_tools : this.props.selectedAppointment.toolsList });
        }
    }
  
    saveSelectedToolsEdit() {
        this.setState({ showConfirmSave: true });
    }

    getAppointmentSelectedTool(appointId) {
        const { t, selectedAppointment } = this.props;
        AppointmentApiInvoker.getAppointmentSelectedTool(appointId, dt => {
            selectedAppointment.toolsList = dt;
            this.setState({ showConfirmSave: false, has_changes: false });
            this.openAlert('success', t('appointment.tools.saveMessageConfirm'));
            this.props.afterAppointmentToolsSave();
        });
    }

    deleteAppointmentTool(appointId, toolId) {
        const { removeItems } = this.state;
        AppointmentApiInvoker.deleteAppointmentTool(appointId, toolId, () => {
            removeItems.splice(0, removeItems.length);
            this.setState({ loading: false, has_changes: false });    
        }, () => {
            this.setState({ loading: false });
        });
    }

    postAppointmentTool(appointId) {
        const { checkedToolsList } = this.props;
        if (checkedToolsList.length == 0) {
            this.getAppointmentSelectedTool(appointId);
            return;
        }
        AppointmentApiInvoker.postAppointmentTool(appointId, checkedToolsList, (data) => {
            if (data.message) {
                this.setState({ showConfirmSave: false });
                this.openAlert('success', data.message);
                this.setState({ alertErrorOpen: true, alertErrorMessage: data.message, alertStatus: 'error', has_changes: false });
            } else {
                checkedToolsList.splice(0,checkedToolsList.length);
            }
            this.getAppointmentSelectedTool(appointId);
        }, (err) => {
            this.props.onAppointmentToolsSaveError(err);
            if (err.message === 'Unexpected end of JSON input') {
                this.setState({ showConfirmSave: false, loading: false });
                this.openAlert('danger', 'error end of json input');
                return;
            }
            this.setState({ showConfirmSave: false, loading: false });
            const message = err.message ?? err;
            this.openAlert('danger', message + '');
        });
    }

    confirmSave = () => {
        const { appointmentId, selectedAppointment } = this.props;
        const { appointmentIdToSave, removeItems } = this.state;
        this.setState({ busy: true });
        const appointId = appointmentIdToSave || appointmentId || selectedAppointment.appointmentId;
        if (!appointId) {
            return;
        }
        if (removeItems.length !== 0){
            removeItems.forEach(i => {
                const itemDelAdded = this.holdIds(this.tableData()).find(f => f === i.toolId);
                if(itemDelAdded) {
                    const index = removeItems.findIndex(idx => idx.toolId === itemDelAdded);
                    if(index !== -1) removeItems.splice(index, 1);
                    return;
                }
                if(removeItems.length !== 0){
                    this.deleteAppointmentTool(appointId, i.toolId);
                }
            });
        }
        this.postAppointmentTool(appointId);
    }

    cancelSave = () => {
        this.setState({ showConfirmSave: false, busy: false });
    }

    openAlert = (color, message) => {
        this.setState({
            color: color,
            message: message,
            openDialog: true,
        });
        setTimeout(() => {
             this.setState({ openDialog: false });
        }, 5000);
    }

    confirmUndo = () => {
        const { t, checkedToolsList } = this.props;
        checkedToolsList.splice(0, checkedToolsList.length);
        this.setState({ removeItems: [], showConfirmUndo: false, has_changes: false });
        this.openAlert('success', t('common.undoMessageConfirm'));
    }

    cancelUndo = () => {
        this.setState({ showConfirmUndo: false });
    }

    discardEdit = () => {
        this.setState({ showConfirmUndo: true });
    }

    deleteTool(tool) {
        const isEqualState = this.compareArrays(this.tableData(), this.props.selectedAppointment.toolsList);
        
        const index = this.props.checkedToolsList.indexOf(tool.toolId);
        if(index !== -1) this.props.checkedToolsList.splice(index, 1);

        const addToRemove = this.holdIds(this.props.selectedAppointment.toolsList).indexOf(tool.toolId) !== -1;

        const exist = this.state.removeItems ? this.state.removeItems.filter(t => t.toolId === tool.toolId).length === 1 : false;
        if(addToRemove && !exist) this.state.removeItems.push(tool);
        this.setState({ has_changes: !isEqualState });
    }

    deselectTool(tool, action) {
        if (action === 'deselect') {
            this.props.onCheckAppointmentTools(tool);
        }
        if (action === 'delete') {
            this.deleteTool(tool);
        }
    }

    getSelectedTools(cb) {
        this.setState({ loading: true });
        let appId = this.props.appointmentId;
        if (!appId){
            appId = this.props.selectedAppointment?this.props.selectedAppointment.appointmentId : '';
        }

        AppointmentApiInvoker.getAppointmentSelectedTool(appId, data => {
            this.setState({ loading: false });
            return cb(data);
        }, () => {
            this.setState({ loading: false });
        });
    }

    saveSelectedTool() {
      if(this.props.checkedToolsList.length !== 0){
        AppointmentApiInvoker.postAppointmentTool(this.props.appointmentId, this.props.checkedToolsList, this.props.onSave)
      } else {
        this.props.onSave();
      }
    }
    
    confirmExit = () => {
        this.props.onBack();
    }

    cancelExit() {
        this.setState({ showConfirmExit: false });
    }

    onBack = () => {
        const { eventChangesPending, checkedToolsList, removeItems, onBack } = this.props;
        if (eventChangesPending || checkedToolsList?.length > 0 || removeItems?.length > 0) {
            this.setState({ showConfirmExit: true });
        } else {
            onBack();
        }
    }

    pendingRemove = (idv) => this.state.removeItems.filter(i => i.toolId === idv).length > 0;

    mustHideButtons = () => {
        const { assignAgenda, componentAction, hideControlButtons } = this.props;
        const hideButtons = hideControlButtons || (componentAction === 'view' && assignAgenda) || componentAction === 'edit';
        return hideButtons;
    }

    renderButtons = () => {
        const { t, componentAction } = this.props;
        const isHideButtons = this.mustHideButtons();
        if (isHideButtons) {
            return <div />;
        }

        if (componentAction === 'view') {
            return (
                <GridItem className="buttonFooter mode-only-view" xs={12}>
                    <Button id="button-back" onClick={() => browserHistory.goBack()} className="buttonPlusFooter">
                        {t('appointment.tools.back.tittle')}
                    </Button>
                </GridItem>
            )
        }

        return (
            <GridItem className="buttonFooter other-cases" xs={12}>
                <Button id="button-cancel" onClick={() => browserHistory.push('/solicitudes')} className="buttonPlusFooter" color="danger">
                    {t('common.cancel')}
                </Button>
                <Button id="button-accept" onClick={this.saveSelectedTool.bind(this)} className="buttonPlusFooter" color="primary">
                    {t('appointment.new.accept')}
                </Button>
            </GridItem>
        );
    }

    openDialog = (tools) => {
        this.setState({ open: true, tools_hold: tools });
    }

    closeModalOnly = () => {
        this.setState({ open: false, type: '', component: null, current: null });
    }

    closeModal() {
        const { tools_hold } = this.state;
        const { appointmentsTools } = this.props;
        const list = appointmentsTools.filter(tool => tools_hold.includes(tool.toolId));

        this.props.onUndoSelectedTools(tools_hold, list);
        this.setState({ open: false, type: '', component: null, current: null });
    }

    compareArrays = (array1, array2) => {
        const arr1 = this.holdIds(array1);
        const arr2 = this.holdIds(array2);
  
        if (!arr1 || !arr2) {
            return false;
        }
        if (arr1.length !== arr2.length) {
            return false;
        }
        
        return arr1.reduce((reducer, value) => {
            return reducer === false ? false : arr2.includes(value);
        }, true);
    }

    disabledButton = () => {
        const isEqualState = this.compareArrays(this.tableData(), this.props.selectedAppointment.toolsList);
        return (this.props.checkedToolsList.length === 0 && this.state.removeItems.length === 0) || isEqualState;
    }

    holdIds = (data) => data?.map(m => m.toolId);

    formatTool(tool, action) {
        return {
            ...tool,
            action: <Button justIcon color="transparent" onClick={() => this.deselectTool(tool, action)} id={`button-${action}-${tool.toolId}`} key={tool.toolId}>
                <Delete className="danger" />
            </Button>
        }
    }

    tableData = () => {
        const { appointmentsTools, checkedToolsList, componentAction, selectedAppointment } = this.props;
        let data;
        const { toolsList } = selectedAppointment;
        if (componentAction === 'edit') {
            if (toolsList) {
                data = toolsList.map(i => this.formatTool(i, 'delete')).concat(
                    appointmentsTools.filter(app => {
                        return checkedToolsList.indexOf(app.toolId) !== -1;
                    }).map(i => this.formatTool(i, 'delete'))
                )
                const ret = [];

                data.forEach(item => {
                    const isCheckedToolsList = this.props.checkedToolsList.includes(item.toolId);
                    if (!this.pendingRemove(item.toolId) || isCheckedToolsList) {
                        ret.push(item);
                    }
                });                
                return ret;
            }
        }
        if (componentAction === 'create') {
            data = appointmentsTools.filter(app => {
                return checkedToolsList.indexOf(app.toolId) !== -1;
            }).map(i => this.formatTool(i, 'deselect'));
            return data;
        }
        return selectedAppointment?.toolsList;
    }

    render() {
        const {
            t, assignAgenda, componentAction, iconCardColor, message, selectedAppointment, viewMode, startDate, endDate,
            onGetAppointmentsTools, onCheckAppointmentTools, onCheckAppointmentToolsEdit, checkedToolsList,
        } = this.props;

        const { action, busy, color, openDialog, current, loading, open, removeItems, showConfirmExit, showConfirmSave, showConfirmUndo, } = this.state;

        return (
           <GridContainer id="tools-table" className="table-supplies-appointment check-input">
                <GridItem xs={12}>
                    <Card className="table">
                        {showConfirmUndo &&
                            <CustomSweetAlert
                                type="warning"
                                title={t('common.undoMessage')}
                                onConfirm={this.confirmUndo}
                                onCancel={() => this.cancelUndo()}
                                confirmBtnCssClass="btn primary"
                                cancelBtnCssClass="btn danger"
                                confirmBtnText={t('common.confirm')}
                                cancelBtnText={t('common.cancel')}
                                showCancel
                            />
                        }

                        {showConfirmSave &&
                            <CustomSweetAlert
                                type="warning"
                                title={t('common.saveChangesMessage')}
                                onConfirm={this.confirmSave}
                                onCancel={() => this.cancelSave()}
                                showCancel={false}
                                showConfirm={false}
                                message={
                                    <>
                                        {message}
                                        <div className="customAlertButtons">
                                            <Button
                                                id="button-cancel-save"
                                                color="danger"
                                                onClick={() => this.cancelSave()}
                                            >
                                                {t('common.cancel')}
                                            </Button>
                                            <ButtonSpinner
                                                disabled={busy}
                                                label={t('appointment.tools.confirm')}
                                                labelLoading={t('common.saving')}
                                                loading={busy}
                                                typeButton="submit"
                                                color="primary"
                                                onClick={this.confirmSave}
                                                id="button-confirm-save"
                                            />
                                        </div>
                                    </>
                                }
                            />
                        }

                        {showConfirmExit &&
                            <CustomSweetAlert
                                type="warning"
                                title={t('appointment.tools.discardMessage')}
                                onConfirm={this.confirmExit}
                                onCancel={() => this.cancelExit()}
                                confirmBtnCssClass="btn primary"
                                cancelBtnCssClass="btn danger"
                                confirmBtnText={t('common.confirm')}
                                cancelBtnText={t('common.cancel')}
                                showCancel
                            />
                        }

                        <Snackbar
                            place="tr"
                            color={color}
                            message={this.state.message}
                            open={openDialog}
                        />

                        <CardHeader icon>
                            <CardIcon color={iconCardColor}>
                                <Accessible />
                            </CardIcon>
                            <h3 className="card-icon-title">{ t('appointment.tools.tittle') }</h3>
                        </CardHeader>
                        <CardBody>
                            <Table
                                filtrable={true}
                                loading={loading}
                                tableHeaderColor="primary"
                                defaultPageSize={5}
                                tableHead={[
                                    { Header: '#', accessor: 'toolId'},
                                    { Header: t('appointment.tools.type'), accessor: 'toolType.name' },
                                    { Header: t('appointment.tools.code'), accessor: 'toolType.toolTypeCode' },
                                    { Header: t('appointment.tools.serialNumber'), accessor: 'serialNumber' },
                                    { Header: t('appointment.tools.detail'), accessor: 'detail' },
                                    { Header: t('appointment.tools.actions'), accessor: 'action'}
                                ]}
                                sortable={true}
                                tableData={this.tableData()}
                                colorsColls={['primary']}
                                showPaginationBottom={true}
                            />
                        </CardBody>
                        {(componentAction !== 'view') &&
                         <CardFooter className="schedule-form-submit buttonFooter">
                            <Button
                                id="button-tools-plus"
                                className="buttonPlusFooter"
                                onClick={() => this.openDialog(this.holdIds(this.tableData()))}
                                color="primary"
                            >
                                <Add />
                            </Button>
                        </CardFooter>
                        }
                       
                        {viewMode === 'edit' &&
                            <GridContainer className="actions-table justify-center">
                                <GridItem>
                                    <Button
                                        disabled={this.disabledButton()}
                                        color="danger"
                                        onClick={this.discardEdit}
                                        id="button-tools-undo"
                                    >
                                        {t('appointment.tools.undo')}
                                    </Button>
                                    <Button
                                        color="primary"
                                        disabled={this.disabledButton()}
                                        onClick={() => this.saveSelectedToolsEdit()}
                                        id="button-tools-apply"
                                    >
                                        {t('appointment.tools.save')}
                                    </Button>
                                </GridItem>
                        </GridContainer>
                        }
                    </Card>                     
                    {viewMode !== 'copy' && componentAction === 'edit' && (componentAction === 'edit' && !assignAgenda) &&
                        <GridContainer className="back-button-content justify-center">
                            <GridItem>
                                <Button id="button-onback" onClick={() => this.onBack()}>
                                    {t('appointment.tools.back.tittle')}
                                </Button>
                            </GridItem>
                        </GridContainer>
                    }
                </GridItem>
                
                {this.renderButtons()}

                <CustomDialog
                    title={t('appointment.tools.modal.tittle')}
                    open={open} onClose={() => this.closeModal()}
                >
                    <AppointmentsToolsService.Modal
                        componentAction={componentAction}
                        current={current}
                        open={open}
                        action={action}
                        closeModal={() => this.closeModalOnly()}
                        onGetAppointmentsTools={onGetAppointmentsTools}
                        onCheckAppointmentTools={onCheckAppointmentTools}
                        onCkeckAppointmentToolsEdit={onCheckAppointmentToolsEdit}
                        checkedToolsList={checkedToolsList}
                        dates={{
                            dateFrom: startDate, 
                            dateTo: endDate
                        }}
                        holdIds={this.holdIds(this.tableData())}
                        selectedAppointment={selectedAppointment}
                        removedItems={removeItems}
                    />
                </CustomDialog>
            </GridContainer>
        )
    }
}

AppointmentTools.defaultProps = {
    appointmentsTools: [],
    checkedToolsList: [],
    appointmentId: '',
    componentAction: 'create',
    selectedAppointment: {},
    onGetAppointmentsTools: () => {},
    afterAppointmentToolsSave: () => {},
    onAppointmentToolsSaveError: () => {},
    onCheckAppointmentToolsEdit: () => {},
    hideControlButtons: false,
    performSave: false,
    onClearSelectedTools: () => {},
    onSave: () => {},
    assignAgenda: false,
    iconCardColor: 'secondary',
}

AppointmentTools.propTypes = {
    t: PropTypes.func,
    onClearSelectedTools: PropTypes.func,
    componentAction: PropTypes.oneOf(['create', 'view', 'edit']),
    selectedAppointment: PropTypes.object,
    afterAppointmentToolsSave: PropTypes.func,
    assignAgenda: PropTypes.bool,
    appointmentId: PropTypes.any,
    appointmentsTools: PropTypes.array,
    checkedToolsList: PropTypes.array,
    onGetAppointmentsTools: PropTypes.func,
    onCheckAppointmentToolsEdit: PropTypes.func,
    onAppointmentToolsSaveError: PropTypes.func,
    onSave: PropTypes.func,
    hideControlButtons: PropTypes.bool,
    performSave: PropTypes.bool,
    iconCardColor: PropTypes.oneOf(['primary', 'secondary']),
    onCheckAppointmentTools: PropTypes.func,
    onBack: PropTypes.func,
    eventChangesPending: PropTypes.bool,
    onUndoSelectedTools: PropTypes.func,
    message: PropTypes.string,
    viewMode: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    removeItems: PropTypes.array,
}

export default withTranslation()(AppointmentTools);
